function applyStyle(el, style) {
    Object.keys(style).forEach(function (key) {
        el.style[key] = style[key];
    });
}
export default (function (editor, options) {
    if (options === void 0) { options = {}; }
    var defaultOpts = {
        appendTo: document.body,
        start: true,
        style: {
            padding: '4px',
            backgroundColor: 'white',
            opacity: .6,
            position: 'fixed',
            zIndex: 10,
            transition: 'width 0.2s ease-out',
            pointerEvents: 'none',
        },
        visibleStyle: {
            visibility: 'visible',
            width: '100%',
        },
        hiddenStyle: {
            visibility: 'hidden',
            width: '0%',
        }
    };
    var opts = Object.assign(defaultOpts, options);
    // Create element
    var loadingEl = document.createElement('div');
    editor.on('load', function () {
        var container = typeof opts.appendTo === 'string' ? document.querySelector(opts.appendTo) : opts.appendTo;
        if (opts.start)
            container.insertBefore(loadingEl, container.firstChild);
        else
            container.appendChild(loadingEl);
    });
    // Style
    applyStyle(loadingEl, opts.style);
    // Loading visibility
    loadingEl.style.visibility = 'hidden';
    editor.on('storage:start', function () {
        applyStyle(loadingEl, opts.visibleStyle);
    });
    editor.on('storage:end', function () {
        applyStyle(loadingEl, opts.hiddenStyle);
    });
});
